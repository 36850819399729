/**
 *
 * Utilities
 * Display
 *
 */


/* Variables */

$display:(
	name: d,
	property: display,
	value:(
		flex: flex,
		grid: grid,
		none: none,
		inline-block: inline-block,
	),
	breakpoints: (sm, md,lg),
);

/* Styles */

@include utility($display);