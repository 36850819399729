/*!
 * Project: SV starter
 * Author: Six Vallées
 * E-mail: thibaud@sixvallees.com
 * Website: sixvallees.com
 */


@use 'sass:math';

/* Settings */

@import "1-settings/settings.breakpoints.scss";
@import "1-settings/settings.colors.scss";
@import "1-settings/settings.typography.scss";
@import "1-settings/settings.spaces.scss";
@import "1-settings/settings.radius.scss";


/* Tools */

@import "2-tools/tools.functions.scss";
@import "2-tools/tools.mixins.scss";

/* Generic */

@import "3-generic/generic.reset.scss";

/* Elements */

@import "4-elements/elements.document.scss";
@import "4-elements/elements.headings.scss";
@import "4-elements/elements.texts.scss";
@import "4-elements/elements.links.scss";
@import "4-elements/elements.lists.scss";
@import "4-elements/elements.tables.scss";


/* Objects */

@import "5-objects/objects.container.scss";
@import "5-objects/objects.spacer.scss";
@import "5-objects/objects.panel.scss";

/* Components */



/* Utilities */

@import "7-utilities/utilities.background.scss";
@import "7-utilities/utilities.boxAlignment.scss";
@import "7-utilities/utilities.grid.scss";
@import "7-utilities/utilities.display.scss";
@import "7-utilities/utilities.spacing.scss";
@import "7-utilities/utilities.text.scss";
@import "7-utilities/utilities.flexDirection.scss";
@import "7-utilities/utilities.justifyContent.scss";
@import "7-utilities/utilities.height.scss";
