/**
 *
 * Utilities
 * Height
 *
 */


/* Variables */

$vh:(
	name: vh,
	property: height,
	value:( 
		100: 100vh
	),
);

/* Styles */

@include utility($vh);