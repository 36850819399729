/**
 *
 * Elements
 * Links
 *
 */


%link {
	color: var(--c-lights);
	text-decoration: underline;
	&:hover{
		color: var(--c-links-hover);
	}
	&:focus{
		outline: none;
	}
}

a{
	@extend %link;
}