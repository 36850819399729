/**
 *
 * Objects
 * Spacer
 *
 */

 
@for $i from 1 through 6 {
	.o-spacer-#{$i}{
		height: var(--space-#{$i});;
	}
}