/**
 *
 * Utilities
 * Grid
 *
 */


/* Variables */


$grid-bp-default: $bp-default;
$grid-bp: (sm, md, lg);
$grid-columns: 12;
$grid-rows: 7;
$gap: 10;

// Grid columns
$grid-cols:();
@for $i from 1 through $grid-columns {
	$value : repeat($i,minmax(0,1fr));
	$grid-cols: map-set($grid-cols,$i,$value);
}
$grid-cols:(
	name: grid-cols,
	property: grid-template-columns,
	value: $grid-cols,
	breakpoints: $grid-bp,
);

// Grid col span
$col-span:();
@for $i from 1 through $grid-columns {
	$value : span $i / span $i;
	$col-span: map-set($col-span,$i,$value);
}
$col-span:(
	name: col-span,
	property: grid-column,
	value: $col-span,
	breakpoints: $grid-bp,
	additional: (align-content: start),
);

// Grid col start
$col-start:();
@for $i from 1 through $grid-columns {
	$value : $i;
	$col-start: map-set($col-start,$i,$value);
}
$col-start:(
	name: col-start,
	property: grid-column-start,
	value: $col-start,
	breakpoints: $grid-bp,
	additional: (align-content: start),
);

// Grid col end
$col-end:();
@for $i from 1 through $grid-columns {
	$value : $i;
	$col-end: map-set($col-end,$i,$value);
}
$col-end:(
	name: col-end,
	property: grid-column-end,
	value: $col-end,
	breakpoints: $grid-bp,
	additional: (align-content: end),
);

// Grid gap
$grid-gap:();
@for $i from 1 through $gap {
	$value : $i+rem;
	$grid-gap: map-set($grid-gap,$i,$value);
}
$grid-gap:(
	name: gap,
	property: gap,
	value: $grid-gap,
	breakpoints: $grid-bp,
);

.u-grid{

	/* MOBILE */
	--grid-gap: 1.5rem;

	/* TABLET */
	@include media-up(md){
		--grid-gap: 2rem;
	}

	/* DESKTOP */
	@include media-up(lg){
		--grid-gap: 4rem;
	}

}

/* Styles */

.u-grid{
	display: grid !important;
	align-content: start;
	gap: var(--grid-gap);
}



@include utility($grid-cols);
@include utility($col-span);
@include utility($col-start);
@include utility($col-end);
@include utility($grid-gap);