/**
 *
 * Utilities
 * Flex direction
 *
 */

 
 /* Variables */
 
$flex-direction:(
	name: flex-direction,
	property: flex-direction,
	value:(
			row: row,
			column: column,
	),
	breakpoints: (sm, md,lg),
);

/* Placeholders */

/* Styles */

@include utility($flex-direction);

