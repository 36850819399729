/**
 *
 * Utilities
 * Spacing
 *
 */


/* Variables */

$spacing-bp-default: $bp-default;
$spacing-bp: (sm,md,lg);
$spacing-amounts: (-50,-40,-30,-20,-10,0,-5,5,10,20,30,40,50);

$spacings:();
@each $space in $spacing-amounts {
	$i: index($spacing-amounts, $space);
	$value: #{$space/10}rem;
	$spacings: map-set($spacings,$space,$value);
}

$mt:(
	name: mt,
	property: margin-top,
	value: $spacings,
	breakpoints: $spacing-bp,
);

$mb:(
	name: mb,
	property: margin-bottom,
	value: $spacings,
	breakpoints: $spacing-bp,
);

$ml:(
	name: ml,
	property: margin-left,
	value: $spacings,
	breakpoints: $spacing-bp,
);

$mr:(
	name: mr,
	property: margin-right,
	value: $spacings,
	breakpoints: $spacing-bp,
);

$pt:(
	name: pt,
	property: padding-top,
	value: $spacings,
	breakpoints: $spacing-bp,
);

$pb:(
	name: pb,
	property: padding-bottom,
	value: $spacings,
	breakpoints: $spacing-bp,
);

$pl:(
	name: pl,
	property: padding-left,
	value: $spacings,
	breakpoints: $spacing-bp,
);

$pr:(
	name: pr,
	property: padding-right,
	value: $spacings,
	breakpoints: $spacing-bp,
);


$p:(
	name: p,
	property: padding,
	value: $spacings,
	breakpoints: $spacing-bp,
);


/* Styles */

@include utility($mt);
@include utility($mb);
@include utility($ml);
@include utility($mr);

@include utility($pt);
@include utility($pb);
@include utility($pl);
@include utility($pr);
@include utility($p);