/**
 *
 * Objects
 * Panel
 *
 */


.o-panel{
	padding: 2rem;
	border-radius: var(--radius-1);
}