/**
 *
 * Elements
 * Texts
 *
 */


p{
	font-weight:300;
	margin-bottom: .5rem;
	font-size: var(--f-p);
	font-family: var(--f-main);
	line-height: var(--f-lh);
	color: var(--c-dark);
}

blockquote{
	background: var(--c-gray-5);
	padding: 2rem;
	margin-top: 2rem;
	border-radius: 0 var(--radius-2) var(--radius-2) 0;
	border-left: solid var(--c-grey-50);
}
