/**
 *
 * Elements
 * Tables
 *
 */
  

table {
	width: 100%;
	border-spacing: 0;
}
th {
	text-align: inherit;
}
