/**
 *
 * Settings
 * Colors
 *
 */


/* Mode */

@mixin mode($mode){
	[mode="#{$mode}"]{
		@content
	}
}

:root {
	// Primary
	--c-pr-rgb: 255,103,0;
	--c-pr: rgb(var(--c-pr-rgb));
	--c-pr-tr1: rgba(var(--c-pr-rgb), 0.16);
	// Sedondary
	--c-sc: #f1f1ec;
	// Black
	--c-dark-rgb: 19,19,19;
	--c-dark: rgb(var(--c-dark-rgb));
	--c-dark-tr1: rgba(var(--c-dark-rgb), 0.04);
	--c-dark-tr2: rgba(var(--c-dark-rgb), 0.08);
	--c-dark-tr3: rgba(var(--c-dark-rgb), 0.12);
	--c-dark-1: #F2F2F2;
	--c-dark-2: #E5E5E5;
	--c-dark-3: #D9D9D9;
	--c-dark-4: #B2B2B2;
	--c-dark-5: #7F7F7F;
	--c-dark-6: #666666;
	--c-dark-7: #5c5c5c;
	--c-dark-8: #333333;
	--c-dark-9: #1A1A1A;
	// White
	--c-light-rgb: 255,255,255;
	--c-light: rgb(var(--c-light-rgb));
	--c-light-tr1: rgba(var(--c-light), 0.08);
	--c-light-tr2: rgba(var(--c-light), 0.12);
	--c-light-tr3: rgba(var(--c-light), 0.16);
	--c-light-tr4: rgba(var(--c-light), 0.24);
	// Semantic
	--c-warning: #CD5300;
	--c-success: #027700;
	--c-error: #B20000;
	--c-hover: #993E00;
	// Links
	--c-link: var(--c-pr);
	--c-link-hover: var(--c-pr-3);
}

@include mode(dark){
// Sedondary
--c-sc: #222222;
// Black
--c-dark-rgb: 255,255,255;
--c-dark: rgb(var(--c-dark-rgb));
--c-dark-9: #F2F2F2;
--c-dark-8: #E5E5E5;
--c-dark-7: #D9D9D9;
--c-dark-6: #B2B2B2;
--c-dark-5: #7F7F7F;
--c-dark-4: #666666;
--c-dark-3: #5c5c5c;
--c-dark-2: #333333;
--c-dark-1: #1A1A1A;
// White
--c-light-rgb: 0,0,0;
--c-light: rgb(var(--c-light-rgb));

background: var(--c-light) ;
	
}