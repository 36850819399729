/**
 *
 * Elements
 * Headings
 *
 */


%heading {
	font-weight: 700;
	margin-bottom: 1rem;
	font-family: var(--f-brand-2);
	font-weight: normal;
	text-transform: uppercase;
}

@for $i from 1 through 5 {
	h#{$i}, .h#{$i}{
		font-size: var(--f-h#{$i});
		@extend %heading;
	}
}