/**
 *
 * Settings
 * Radius
 *
 */



 :root {

	// MOBILE
	--radius-1: 2rem;
    --radius-2: 4rem;

	// TABLET
	@include media-up(md){

		--radius-1: 2rem;
        --radius-2: 4rem;

	}

	// DESKTOP
	@include media-up(lg){

		--radius-1: 2rem;
        --radius-2: 4rem;

	}

}