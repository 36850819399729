/**
 *
 * Settings
 * Breakpoints
 *
 */

	
 $breakpoints: (
	sm :	620,
	md : 780,
	lg :	1200
) !default;
$bp-default: 'sm';


/* Functions */

@function em($pixels, $context: 16px) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}
	@if (unitless($context)) {
		$context: $context * 1px;
	}
	@return math.div($pixels, $context) * 1em;
}


/* Mixins */

@mixin media-up($size) {
	$bp: map-get($breakpoints, $size);
	@media only screen and (min-width: em($bp)){
			@content;
	}
}

@mixin media-down($size) {
	$bp: map-get($breakpoints, $size);
	@media only screen and (max-width: em($bp)){
			@content;
	}
}

@mixin media-bp($from, $to) {
	$bp1: map-get($breakpoints, $from);
	$bp2: map-get($breakpoints, $to);
	@media only screen and (min-width: em($bp1)) and (max-width: em($bp2)) {
			@content;
	}	
}

@mixin media-utilities($size) {
	$bp: map-get($breakpoints, $size);
	@media only screen and (min-width: em($bp)){
			@content;
	}
}

@mixin media-custom-up($size) {
	$bp: em($size);
	@media only screen and (min-width: $bp ) {
		@content;
	}
}