/**
 *
 * Utilities
 * Background
 *
 */


/* Variables */

$bg-colors:(
	name: bg,
	property: background,
	value:(
		primary: var(--c-pr),
		dark1: var(--c-dark-1),
		dark2: var(--c-dark-2),
		dark3: var(--c-dark-3),
		dark4: var(--c-dark-4),
		dark5: var(--c-dark-5),
		dark6: var(--c-dark-6),
		dark7: var(--c-dark-7),
		dark8: var(--c-dark-8),
		dark9: var(--c-dark-9),
		dark: var(--c-dark),
		light: var(--c-light)
	)
);

$bg-postions:(
	name: bg-position,
	property: background-position,
	value:(
		top: top,
		center: center,
		bottom: bottom,
	)
);

$bg-size:(
	name: bg-size,
	property: background-size,
	value:(
		auto: auto,
		cover: cover,
		contain: contain,
	)
);

/* Placeholders */

%background-video{
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: -1;
	video{
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}

/* Styles */

// Colors

@include utility($bg-colors);

// Video

.u-bg-video{
	@extend %background-video;
}

// Postions

@include utility($bg-postions);

// Sizes

@include utility($bg-size);