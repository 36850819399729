/**
 *
 * Tools
 * Functions
 *
 */




/* Map set */


@function map-set($map, $key, $value) {
	$new: (
		$key: $value,
	);
	@return map-merge($map, $new);
}



/* String replace */


@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}