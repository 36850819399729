/**
 *
 * Utilities
 * Justify Content
 *
 */

 
 /* Variables */
 
 $justify-content:(
	name: justify-content,
	property: justify-content,
	value:(
        start: start,
		end: end,
		center: center,
		between: space-between,
	),
    breakpoints: (sm, md,lg),
);

/* Styles */

@include utility($justify-content);

