/**
 *
 * Utilities
 * Box Alignement
 *
 */

/* Variables */

$box-align-items:(
	name: align-items,
	property: align-items,
	value:(
		start: start,
		end: end,
		center: center,
	),
	breakpoints: (sm, md,lg),
);

$box-align-self:(
	name: align-self,
	property: align-self,
	value:(
		start: start,
		end: end,
		center: center,
	),
	breakpoints: (sm, md,lg),
);

/* Styles */

// Box align-items

@include utility($box-align-items);

// Box align-self

@include utility($box-align-self);