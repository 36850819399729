/**
 *
 * Settings
 * Typography
 *
 */

 @font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Light.woff2') format('woff2'),
			url('../fonts/MaisonNeue-Light.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Book.woff2') format('woff2'),
			url('../fonts/MaisonNeue-Book.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Medium.woff2') format('woff2'),
			url('../fonts/MaisonNeue-Medium.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Demi.woff2') format('woff2'),
			url('../fonts/MaisonNeue-Demi.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Bold.woff2') format('woff2'),
			url('../fonts/MaisonNeue-Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}


@font-face {
	font-family: 'Maison Neue mono';
	src: url('../fonts/MaisonNeue-MonoItalic.woff2') format('woff2'),
			url('../fonts/MaisonNeue-MonoItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Maison Neue mono';
	src: url('../fonts/MaisonNeue-Mono.woff2') format('woff2'),
			url('../fonts/MaisonNeue-Mono.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


:root{

	--f-main: 'Maison Neue', sans-serif;
	--f-mono: 'Maison Neue mono', mono;

	// MOBILE

	// Line-height
	--f-lh:1.3em;

	// Font-size
	--f-p: 1.5rem;
	--f-sm: 1.3rem;
	--f-h1: 3rem;
	--f-h2: 2.5rem;
	--f-h3: 2rem;
	--f-h4: 1.8rem;
	--f-h5: 1.6rem;

	// TABLET
	@include media-up(md){

		// Font-size
		--f-p: 1.6rem;
		--f-sm: 1.4rem;
		--f-h1: 4rem;
		--f-h2: 3rem;
		--f-h3: 2.2rem;
		--f-h4: 1.9rem;
		--f-h5: 1.7rem;

	}

	// DESKTOP
	@include media-up(lg){

		// Font-size
		--f-h1: 5rem;
		--f-h2: 3.5rem;
		--f-h3: 2.5rem;
		--f-h4: 2rem;
		--f-h5: 1.8rem;

	}

}