/**
 *
 * Elements
 * Document
 *
 */


html {
	color: var(--c-dark);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

::selection {
	background: var(--c-pr);
	color: var(--c-dark);
}
