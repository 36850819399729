/**
 *
 * Tools
 * Mixins
 *
 */


/* Reset */

@mixin list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin btn-reset {
	border: none;
	background: none;
	padding: 0;
}

@mixin link-reset {
	text-decoration: none;
	color: #000;
}


/* Position */


@mixin relative-center {
	margin-left: auto;
	margin-right: auto;
}

@mixin absolute-center {
	position: absolute;
	left: 50%;
	top: 50%;
	display: block;
	transform: translate3d(-50%, -50%, 0);
}

@mixin absolute-vertical-center {
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);
}

@mixin absolute-horizontal-center {
	position: absolute;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
}


/* States */


@mixin hover(){
	&:hover{
		@content;
	}
}

@mixin focus(){
	&:hover{
		@content;
	}
}

@mixin hover-focus(){
	&:hover,&:focus{
		@content;
	}
}


/* Utility */

@mixin utility-generate($name,$prop,$value,$breakpoints,$additional){
	@if $breakpoints {
		.u-#{$name}-#{$value}{
			#{$prop}: #{$value};
			@if $additional{
				@each $prop in $additional{
					#{nth($prop,1)}: #{nth($prop,2)};
				}
			}
		}
		@each $bp in $breakpoints{
			@include media-utilities($bp){
				.u-#{$name}{
					&\:#{$bp}{
						#{$prop}: #{$value};
						@if $additional{
							@each $prop in $additional{
								#{nth($prop,1)}: #{nth($prop,2)};
							}
						}
					}
				}
			}
		}
	} @else {
		.u-#{$name}-#{$value}{
			#{$prop}: #{$value};
			@if $additional{
				@each $prop in $additional{
					#{nth($prop,1)}: #{nth($prop,2)};
				}
			}
		}
	}
}

@mixin utility-generate-map($name,$prop,$value,$breakpoints,$additional){
	@if $breakpoints {
		@each $value in $value{
			$valueName: nth($value,1);
			$valueValue: nth($value,2);
			.u-#{$name}-#{$valueName}{
				#{$prop}: #{$valueValue};
				@if $additional{
					@each $prop in $additional{
						#{nth($prop,1)}: #{nth($prop,2)};
					}
				}
			}
		}
		@each $bp in $breakpoints{
			@include media-utilities($bp){
				@each $value in $value{
					$valueName: nth($value,1);
					$valueValue: nth($value,2);
					.u-#{$name}-#{$valueName}{
						&\:#{$bp}{
							#{$prop}: #{$valueValue};
							@if $additional{
								@each $prop in $additional{
									#{nth($prop,1)}: #{nth($prop,2)};
								}
							}
						}
					}
				}
			}
		}
	} @else {
		@each $value in $value{
			$valueName: nth($value,1);
			$valueValue: nth($value,2);
			.u-#{$name}-#{$valueName}{
				#{$prop}: #{$valueValue};
				@if $additional{
					@each $prop in $additional{
						#{nth($prop,1)}: #{nth($prop,2)};
					}
				}
			}
		}
	}
}


@mixin utility($u){
	$name: map-get($u, 'name');
	$prop: map-get($u, 'property');
	$value: map-get($u, 'value');
	$breakpoints: map-get($u, 'breakpoints');
	$additional: map-get($u, 'additional');
	@if type-of($value) == 'map'{
		@include utility-generate-map($name,$prop,$value,$breakpoints,$additional);
	} @else {
		@include utility-generate($name,$prop,$value,$breakpoints,$additional);
	}
}


/* Font face */

@mixin font-face($family, $filename, $weight, $style) {

	$weight: '400' !default;
	$style: 'normal' !default;

	@font-face {
			font-family: "#{$family}";
			src: url("#{$font-path}/#{$filename}.eot");
			src: local("#{$family}"),
			url("#{$font-path}/#{$filename}.eot?#iefix") format("embedded-opentype"),
			url("#{$font-path}/#{$filename}.woff2") format("woff2"),
			url("#{$font-path}/#{$filename}.woff") format("woff"),
			url("#{$font-path}/#{$filename}.ttf") format("truetype"),
			url("#{$font-path}/#{$filename}.svg##{$filename}") format("svg");
			font-weight: $weight;
			font-style: $style;
			font-display: swap;
	}

}