/**
 *
 * Objects
 * Container
 *
 */


.o-container{
	--container-width: 1200px;
	max-width: var(--container-width);
	width: 90%;
	margin: 0 auto;
}
